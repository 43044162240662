<template>
  <div class="web-view__layout">
    <slot />
    <!-- <Snackbar></Snackbar> -->
  </div>
</template>

<style lang="scss" scoped>
// .web-view__layout {
//   max-width: 500px;
//   margin: 0 auto;
// }
</style>
